@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;700&display=swap')
@import 'variables.module.sass'

html, body
  padding: 0
  margin: 0

*
  box-sizing: border-box

a
  outline: none
  text-decoration: none
  transition: .2s

body
  font-family: 'Kanit', sans-serif
  font-size: 16px
  font-weight: 300
  color: var(--text-color)
  background: var(--bkg-color)
  will-change: transition

.container
  max-width: 1280px
  width: 100%
  margin: auto
  padding: 0 30px

p
  margin: 0

p + p
  margin-top: 20px


.header-row
  display: flex
  justify-content: space-between
  align-items: center

nav
  display: flex
  align-items: center

  ul
    display: flex
    margin: 0
    padding: 0
    list-style: none

    li
      margin-right: 30px

    a
      font-size: 17px
      color: var(--text-color)
      &:hover
       color: $red


.title-block
  font-size: 65px
  margin: 0
  font-weight: 500
  line-height: 1


.teaser
  padding: 150px 0
  position: relative
  display: flex
  align-items: center

h1
  &.title-block
    text-transform: none
    margin-bottom: 10px
    line-height: 1

h3
  font-size: 25px
  font-weight: 300
  margin: 0

.teaser-content
  position: absolute

.blob
  opacity: .3
  max-width: 705px
  position: relative

  svg
    position: relative
    z-index: 1
    width: 705px
    height: 580px

  &::before, &::after
    content: ''
    width: 700px
    height: 700px
    background-image: url('img/patterns/red-blur-min.png')
    position: absolute
    top: -90px
    left: -200px
    background-size: contain
    background-repeat: no-repeat

  &::after
    background-image: url('img/patterns/blue-blur-min.png')
    left: auto
    top: auto
    bottom: -110px
    right: -160px

  //&:after, &:before
  //  content: ''
  //  position: absolute
  //  width: 300px
  //  height: 300px
  //  background: #EB5757
  //  border-radius: 50%
  //  left: -100px
  //  top: calc(50% - 150px)
  //  z-index: -1
  //  filter: blur(150px)
  //&:after
  //  background: #57B6EB
  //  right: 0px
  //  top: 50%
  //  left: auto

.teaser
  .blob
    left: -60px

.blobFooter
  position: absolute
  transform: rotate(225deg)
  top: 10px
  z-index: -1
  left: -425px
  &::after
    right: -200px
    bottom: 100px
    opacity: .4

footer
  .container
    padding-top: 190px
    position: relative

.themeToggle
  transition: .2s
  background: none
  border: none
  padding: 0
  width: 20px
  height: 20px
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  path
    transition: .2s
    fill: var(--text-color)

  svg
    &:hover
      path
        fill: $red

.project-head
  margin-top: 50px


.blobProjects
  position: absolute
  top: 50px
  z-index: -1
  transform: rotate(-195deg)
  right: -300px

  svg

  .previewProject-mask
    position: absolute
    visibility: hidden

.projects-block
  .container
    position: relative


.previewProject
  clip-path: url(#previewProject)
  position: absolute
  top: -2px
  left: 0
  width: 717px
  height: 585px
  z-index: 10
  background-size: cover
  background-position: center
  opacity: 0
  transition: .2s

.load-more-btn
  text-align: center
  margin-top: 40px

.previewProject-mask
  position: absolute
  bottom: 0
  z-index: -1
  visibility: hidden

.project-general-img
  width: 100%
  border-radius: 17px
  margin-top: 40px
  position: relative

.project-head
  position: relative
  img
    position: relative
    z-index: 1
  .container
    position: relative
    z-index: 1
    &::before, &::after
      content: ''
      width: 700px
      height: 700px
      background-image: url('img/patterns/red-blur-min.png')
      position: absolute
      top: auto
      right: -200px
      background-size: contain
      background-repeat: no-repeat
      opacity: .4
      bottom: -400px
    &:after
      background-image: url('img/patterns/blue-blur-min.png')
      left: -200px
      top: -50px
      right: auto
    & > *
      position: relative
      z-index: 1



  h2
    margin-bottom: 20px

  p.desc-project
    margin-top: 15px

  .date
    opacity: .6

.btn
  position: relative
  border: none
  color: var(--text-color)
  padding: 20px 45px
  background-color: #fff0
  font-size: 18px
  border-radius: 50px
  line-height: 1
  font-family: $font
  cursor: pointer
  &:before
    content: ''
    width: 100%
    height: 100%
    border-radius: 50px
    position: absolute
    left: 0
    top: 0
    background: linear-gradient(109.84deg, $red 0.44%, $blue 105.15%)
    transform: translateZ(0)
    z-index: -1
    filter: blur(20px)
    transition: .4s
    opacity: .7
  &:hover
    color: $red
    &:before
      filter: blur(25px)

.btn-back
  font-family: "Kanit", sans-serif
  border: none
  background: none
  color: var(--text-color)
  font-size: 16px
  text-transform: uppercase
  margin-top: 50px
  opacity: .6
  font-weight: 300
  padding: 0
  transition: .2s
  cursor: pointer
  z-index: 6
  position: relative
  &:hover
    color: $red

.gallery-project
  position: relative
  z-index: 5
  img
    width: 100%
    border-radius: 17px
    margin-top: 15px
    position: relative
    z-index: 1
  span
    position: relative
    display: block
    &::before
      content: ''
      width: 700px
      height: 700px
      background-image: url('img/patterns/red-blur-min.png')
      position: absolute
      top: -300px
      left: -150px
      background-size: contain
      background-repeat: no-repeat
      opacity: .4
    &.blur_2
      &::before
        right: -300px
        left: auto
        background-image: url('img/patterns/blue-blur-min.png')
    &.blur_0
      &::before
        right: -300px
        left: auto
        top: -500px
        margin: auto
        background-image: url('img/patterns/blue-blur-min.png')
    &.blur_3
      &::before
        background-image: url('img/patterns/blue-blur-min.png')
    &.blur_4
      &::before
        left: auto
        right: -300px
        top: -400px

.gallery-block
  margin-bottom: -170px
  position: relative
  z-index: 1


.top-head
  display: flex
  justify-content: space-between
  align-items: center

.text-center
  text-align: center

.error-page
  padding-top: 50px
  .container
    position: relative
    display: flex
    align-items: center
    justify-content: center

.error-content
  position: absolute
  font-size: 22px
  h2
    font-size: 150px
    font-weight: 700
  p
    padding-top: 5px
  a
    color: var(--text-color)
    display: block
    font-size: 25px
    margin-top: 15px
    &:hover
      color: $red

.blob-error
  transform: rotate(-170deg)
  svg
    //opacity: 0
  &::before, &::after
    opacity: .8

  &::before
    top: -50px
    left: -40px
  &::after
    top: -100px
    right: -40px

.loading-items
  margin-top: 120px
  display: flex
  flex-flow: wrap
  justify-content: space-between
  svg
    margin-bottom: 60px
    height: 114.5px

.container
  position: relative

.close-icon
  display: flex
  flex-flow: column
  align-items: center
  justify-content: center
  width: 30px
  height: 30px
  cursor: pointer
  margin-left: 10px
  position: relative
  top: 1px
  z-index: 11
  span
    width: 21px
    height: 3px
    background: var(--text-color)
    display: block
    border-radius: 3px
    transform: rotate(45deg)
    position: relative
    transition: .2s
    &:last-child
      opacity: .5
      transform: rotate(-45deg)
      top: -3px
  &:hover
    span
      background: #5CEB57


.alert-error
  .close-icon
    &:hover
      span
        background: $red

.item
  transition: opacity 2s ease-in

.item-enter
  opacity: 0
  visibility: hidden
.item-enter-active
  opacity: 1

.item-exit
  opacity: 1


.item-exit-active
  opacity: 0

.contact-form
  display: flex
  flex-flow: wrap
  justify-content: center
  margin: 0 -15px
  max-width: 100%
  input, textarea
    padding: 15px 0px
    background: none
    border: none
    border-bottom: 1px solid var(--text-color)
    color: var(--text-color)
    resize: none
    outline: none
    font-family: 'Kanit', sans-serif
    font-weight: 300
    font-size: 15px
    transition: .2s
    border-radius: 0
    margin-bottom: 20px
    &.not-valid
      border-color: $red

  textarea
    min-height: 54px
    height: 54px
  ::placeholder
    color: var(--text-color)
    opacity: .5


  .form-left, .form-right
    display: flex
    flex-flow: column
    width: calc(100% - 0px)
    margin: 0 0px 0px
    height: initial

  .form-left
    input
      display: block
      width: 100%


.modal-wrapper
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 100
  display: flex
  align-items: center
  justify-content: center
  backdrop-filter: blur(0px)
  visibility: hidden
  transition: opacity .2s ease-in-out
  opacity: 0
  will-change: transition
  &.modal-wrapper-enter-done
    backdrop-filter: blur(5px)
    opacity: 1
    visibility: visible

  &::before
    content: ''
    background: var(--bkg-color)
    opacity: 0.3
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
  .modal-bg
    position: absolute
    &::before, &::after
      content: ''
      width: 700px
      height: 700px
      background-image: url('img/patterns/red-blur-min.png')
      position: absolute
      background-size: contain
      background-repeat: no-repeat
      opacity: .1
      right: -100px
      top: -200px

    &::after
      background-image: url('img/patterns/blue-blur-min.png')
      right: auto
      opacity: .2
      top: -500px
      left: 0
  .modal
    position: relative
    width: 100%
    padding: 30px
    border-radius: 8px
    backdrop-filter: blur(10px)
    max-width: 450px
    //mix-blend-mode: screen
    will-change: backdrop-filter
    .alert-item
      opacity: 0
      visibility: visible
      transition: .2s
      &.alert-transition-enter-done
        opacity: 1
        visibility: visible
    & > .close-icon
      &:hover
        span
          background: $red
    &::before
      content: ''
      background: var(--bkg-color)
      opacity: 0.5
      border-radius: 8px
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
    form
      position: relative
      z-index: 1

    .btn
      margin-top: 0px
      transition: .2s
      &::before
        filter: blur(15px)

    .close-icon
      position: absolute
      top: 20px
      right: 20px

    .alert-item
      top: -60px
      right: -25px
      .close-icon
        position: relative
        top: auto
        right: auto

  .title-modal
    font-size: 25px
    color: var(--text-color)
    text-align: center
    margin-bottom: 20px
    z-index: 10
    position: relative
    font-weight: 500


.alert-error
  &::before
    background: linear-gradient(109.84deg, #fd7474 0.44%, #FD0E0E 105.15%)





@media screen and (max-width: 991px)
  .blob
    svg
      max-width: 500px
      max-height: 400px

  .title-block
    font-size: 45px

  .blobFooter
    left: -280px

  .blob
    &::before, &::after
      opacity: .7
      left: -90px
      top: -70px
      width: 400px
      height: 400px
    &::after
      left: auto
      top: auto
      right: -60px
      bottom: -60px

  .blobProjects
    right: -280px
    transform: rotate(-170deg)

  h3
    font-size: 20px

  .gallery-project
    img
      border-radius: 5px
      margin-top: 5px

  .project-general-img
    border-radius: 5px
    margin-top: 20px

  .loading-items
    margin-top: 60px

@media screen and (max-width: 565px)
  body
    font-size: 14px
  .blob
    max-width: 360px
    svg
      opacity: .4
      max-width: 100%
    &::after, &::before
      opacity: .5
      width: 300px
      height: 300px
  .teaser
    padding-top: 0
    padding-bottom: 0px
    text-align: center
    .blob
      left: 0
      max-width: 100%
      margin: auto
    .title-block
      font-weight: 400
      margin-bottom: 5px
    h3
      font-size: 15px
      font-weight: 200
    .teaser-content
      width: 100%

  .blobProjects
    top: 0px
    right: -65%

  footer
    .container
      padding-top: 90px

  .title-block
    font-size: 30px
    font-weight: 400

  .blobFooter
    left: -210px
    top: -50px
    &::after
      opacity: 0

  .gallery-block
    margin-bottom: -70px

  .btn-back
    margin-top: 15px

  .top-head
    align-items: center
    h2
      margin-bottom: 0

  .gallery-project
    span
      &::before
        width: 350px
        height: 350px

  .project-head
    .container
      &::before, &::after
        opacity: .2
        width: 300px
        height: 300px

      &::after
        left: -100px
        top: 50px

      &::before
        bottom: -100px
        right: -100px

  .error-content
    font-size: 16px
    h2
      font-size: 75px
      font-weight: 600
    a
      font-size: 18px
      margin-top: 5px

  .error-page
    height: calc(100vh - 396px)
    display: flex
    align-items: center

  .blob
    &::before
      top: 0
    &::after
      bottom: 0

  nav
    ul
      li
        margin-right: 15px
      a
        font-size: 15px

  .loading-items
    margin-top: 30px

  .loading-items
    svg
      margin-bottom: 30px
  .btn-back
    font-size: 14px

  .contact-form
    flex-flow: wrap
    .form-left, .form-right
      width: 100%
      margin-bottom: 0
    .btn
      margin-top: 30px

  .modal-wrapper
    .modal
      width: 95%


.teaser-block, .page-wrapper
  overflow: hidden

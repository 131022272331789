@import "../sass/variables.module.sass"

.projectsBlock
  padding-top: 0px
  position: relative
  padding-bottom: 50px
  overflow: hidden


.projectItem__head
  display: flex
  justify-content: space-between
  margin-bottom: 10px

  .date
    opacity: .5


.title
  font-size: 20px
  font-weight: 400
  transition: .2s
  position: relative
  &::after
    content: ''
    width: 0
    height: 2px
    background: linear-gradient(109.84deg, $red 0.44%, $blue 105.15%)
    border-radius: 2px
    display: block
    position: relative
    bottom: -5px
    transition: .2s


.item
  width: calc(25% - 60px)
  margin: 0 30px 60px
  position: relative
  z-index: 1
  min-height: 115px
  transition: .2s
  color: var(--text-color)
  &:hover
    .title
      &::after
        width: 100%
    .previewProject
      opacity: 1
      img
        width: 100%
        height: auto

    .title
      //color: $red

  p
    margin: 0
    font-size: 15px


.previewProject
  position: absolute
  left: calc(50% - 200px)
  top: calc(50% - 113px)
  width: 400px
  height: 225px
  z-index: -1
  opacity: 0
  transition: .2s
  display: flex
  justify-content: center
  border-radius: 10px
  align-items: center
  img
    width: 0
    opacity: .2
    transition: .2s
    //box-shadow: 0 0 10px $red
    border-radius: 10px
  .blur
    filter: blur(40px)
    position: absolute
    left: 0
    top: 0

.projectsList
  display: flex
  flex-flow: wrap
  min-height: 350px
  margin: 120px -30px 0

.btn
  position: relative
  border: none
  color: var(--text-color)
  padding: 20px 45px
  background-color: #fff0
  font-size: 18px
  border-radius: 50px
  line-height: 1
  font-family: $font
  cursor: pointer
  &:before
    content: ''
    width: 100%
    height: 100%
    border-radius: 50px
    position: absolute
    left: 0
    top: 0
    background: linear-gradient(109.84deg, $red 0.44%, $blue 105.15%)
    transform: translateZ(0)
    z-index: -1
    filter: blur(20px)
    transition: .4s
    opacity: .8

  &:hover
    &:before
      filter: blur(25px)


@media screen and (max-width: 991px)
  .projectsBlock
    padding-top: 100px

  .projectsList
    margin: 60px -20px 0

  .title
    font-size: 17px

  .item
    width: calc(50% - 40px)
    margin: 0 20px 30px
    min-height: auto
    p
      font-size: 14px


  .previewProject
    display: none


@media screen and (max-width: 565px)
  .item
    width: 100%
    p
      font-size: 14px
      font-weight: 200
  .projectItem__head

    .date
      font-size: 14px
      font-weight: 100
      opacity: .8
  .btn
    font-size: 16px
    font-weight: 200
    &::before
      opacity: .4

  .projectsBlock
    padding-top: 0px

  .projectsList
    margin: 30px -20px 0

  .title
    font-size: 16px
    font-weight: 400

.alert
  color: var(--text-color)
  padding: 10px 25px
  display: inline-block
  position: relative
  display: flex
  align-items: center
  position: absolute
  top: 0
  right: 0
  z-index: 1
  &::before
    content: ''
    width: 100%
    height: 100%
    background: linear-gradient(109.84deg, #5CEB57 0.44%, #57EBDD 105.15%)
    position: absolute
    top: 0
    left: 0
    z-index: -1
    opacity: .5
    filter: blur(30px)
    will-change: filter
$bg: #F2F2F2
$text-color: #1e1e1e
$red: #EB5757
$blue: #57B6EB
$font: 'Kanit', sans-serif

\:root
  --red: #EB5757
  --blue: #57B6EB

body
  transition: .2s
  will-change: transition
  --loading-bg: rgba(143, 143, 143, 0.15)
  --loading-animation: rgba(143, 143, 143, 0.19)
@media (prefers-color-scheme: dark)

  body
    --text-color: #F2F2F2
    --bkg-color: #121212

  body.light
    --text-color: #1e1e1e
    --bkg-color: #F2F2F2
@media (prefers-color-scheme: light)

  body.dark
    --text-color: #F2F2F2
    --bkg-color: #121212
  body
    --text-color: #1e1e1e
    --bkg-color: #F2F2F2
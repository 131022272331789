@import "../sass/variables.module.sass"

.socialList
  display: flex
  list-style: none
  flex-flow: wrap
  margin-left: 0
  padding-left: 0
  margin-top: 40px

  li
    margin-right: 30px
    a
      color: var(--text-color)
      font-size: 16px
      cursor: pointer
      &:hover
        color: $red
    &:last-child
      margin-right: 0

.footer
  position: relative
  padding-bottom: 50px
  overflow: hidden

.copyright
  color: var(--text-color)
  text-align: center
  padding-top: 250px
  font-size: 16px


@media screen and (max-width: 991px)
  .copyright
    padding-top: 100px
    font-size: 13px

  .footer
    padding-bottom: 20px

  .socialList
    margin-top: 20px
    li
      a
        font-size: 14px
        font-weight: 200
@import "../sass/variables.module.sass"

header
  padding: 25px 0
  position: relative
  z-index: 1
.logo
  font-size: 25px
  font-weight: 300
  color:  var(--text-color)
  text-transform: lowercase
  line-height: 1
  max-width: 105px
  display: inline-block
  position: relative
  &:hover
    color: $red
  &:after
    content: ''
    width: 180px
    height: 100px
    background-image: url('img/logo.svg')
    transform: translateZ(0)
    position: absolute
    top: -40px
    left: -60px
    background-repeat: no-repeat
    background-size: contain
    filter: blur(50px)
    z-index: -1

@media screen and (max-width: 991px)
  .logo
    font-size: 20px


@media screen and (max-width: 565px)
  .logo
    font-size: 17px
    font-weight: 400
    letter-spacing: 1.2px
    &::after
      filter: blur(30px)
      opacity: .4


